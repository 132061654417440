import React, { useState, useMemo, useCallback, useContext } from "react";
import { GetAllCampaigns, ResendSms, GetAllCampaignsDetails } from "../../API";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Table from "../../component/newTable";
import { useTranslation } from "react-i18next";
import { Context } from "../../context";
import ResendPopUp from "../../component/ResendPopUp";
import CampaignDetailsPopUp from "../../component/CampaignDetailsPopUp";
import Header from "../../component/header";
import DateRangePicker from "../../component/dateRangePicker";
import "./style.css";

export default function AllCampaigns() {
  const [reload, setReload] = useState(false);
  const { reloadCredit, toggleCredit } = useContext(Context);
  const { startDate, endDate } = useContext(Context);
  const [data, setData] = useState([]);
  const [filterSender, setFilterSender] = useState();
  const [filterBatchName, setFilterBatchName] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const { setCampaignDetailsPopUp, setResendPopUp } = useContext(Context);
  const [campaignID, setcampaignID] = useState(null);
  const [smsCount, setsmsCount] = useState(null);
  const [toggleSideBar] = useState(false);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const showCampaignDetails = (id) => {
    setcampaignID(id);
    setCampaignDetailsPopUp(true);
  };

  const showResend = (id, sms) => {
    setcampaignID(id);
    setsmsCount(sms);
    setResendPopUp(true);
  };

  const columns = useMemo(
    () => [
      {
        Header: t("ID"),
        accessor: "ID",
      },
      {
        Header: t("date"),
        accessor: "SendDate",
      },
      {
        Header: t("time"),
        accessor: "SendTime",
      },
      {
        Header: (
          <div className="tableHeaderFilter">
            <div>{t("campaignName")}</div>
            <input
              type="text"
              className="searchFilter"
              value={filterBatchName}
              onChange={(e) => setFilterBatchName(e.target.value)}
              placeholder="Search"
            />
          </div>
        ),
        accessor: "CampaignName",
        Cell: ({ row: { original } }) => {
          return (
            <>
              {original.isInserted === 1 ? <div
                className="viewDetailsStyle"
                onClick={() => navigate(`/DLRTable/${original.ID}`)}
              >
                {original.CampaignName}
              </div> : original.CampaignName}

            </>
          );
        },
      },
      {
        Header: t("count"),
        accessor: "RowsCount",
      },
      {
        Header: t("Vodafone"),
        accessor: "VodafoneCount",
      },
      {
        Header: t("Orange"),
        accessor: "OrangeCount",
      },
      {
        Header: t("Etisalat"),
        accessor: "EtisalatCount",
      },
      {
        Header: t("We"),
        accessor: "WECount",
      },
      {
        Header: (
          <div className="tableHeaderFilter">
            <div>{t("sender")}</div>
            <input
              type="text"
              className="searchFilter"
              value={filterSender}
              onChange={(e) => setFilterSender(e.target.value)}
              placeholder="Search"
            />
          </div>
        ),
        accessor: "Sender",
      },
      {
        Header: t("batchCost"),
        accessor: "ReqCost",
      },
      {
        Header: t("campaignDetails"),
        accessor: "",
        Cell: ({ row: { original } }) => {
          return (
            original.isInserted === 1 ?
              <div
                className="viewDetailsStyle"
                onClick={() => showCampaignDetails(original.ID)}
              >
                {t("viewDetails")}
              </div> : <div> {t("viewDetails")}</div>
          );
        },
      },
      {
        Header: t("status"),
        accessor: "isInserted",
        Cell: ({ row: { original } }) => {
          return (
            <div>
              {original.isInserted === 1 ? t("inserted") : t("declined")}
            </div>
          )
        }
      },
      {
        Header: "",
        accessor: "ReSend",
        Cell: ({ row: { original } }) => {
          if (original.fileName !== 'no file uploaded' && original.isInserted === 1) {
            return (
              <div>
                <button
                  className="exportBtn"
                  onClick={() => showResend(original.ID, original.RowsCount)}
                  disabled={isLoadingBtn}
                >
                  {t("Resend")}
                  {isLoadingBtn && (
                    <span className="spinner-border" role="status"></span>
                  )}
                </button>
              </div>
            );
          } else {
            return null;
          }
        },
      }
    ],
    [t, reload, reloadCredit]
  );

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      setIsLoading(true);
      setData([]);
      GetAllCampaigns({
        "@page": pageIndex,
        "@rowsPerPage": pageSize,
        "@startDate": new Intl.DateTimeFormat("en").format(startDate),
        "@endDate": new Intl.DateTimeFormat("en").format(endDate),
        "@batchNameFilter": filterBatchName,
        "@senderFilter": filterSender
      })
        .then((response) => {
          setIsLoading(false);

          setData(response.data.table1);
          setPageCount(response.data.table2[0].Total);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    },
    [t, reload, reloadCredit, startDate, endDate, filterBatchName, filterSender]
  );

  const exportHandler = () => {
    setIsLoadingBtn(true);
    GetAllCampaignsDetails({
      "@batchNameFilter": filterBatchName,
      "@senderFilter": filterSender,
      "@startDate": new Intl.DateTimeFormat("en").format(startDate),
      "@endDate": new Intl.DateTimeFormat("en").format(endDate),
    })
      .then((response) => {
        setIsLoadingBtn(false);
        let link = document.createElement("a");

        link.download = "Report";
        link.href =
          "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
          response.data;

        if (/android|iPad|iPhone|iPod/i.test(navigator.userAgent))
          link.download += ".xlsx";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        setIsLoadingBtn(false);
      });
  };

  return (
    <div className="scheduleContainer">
      <Header
        title={t("AllCampaigns")}
        toggleSideBar={() => toggleSideBar((s) => !s)}
      />

      <div className="tableMainTitles">
        <DateRangePicker />
        <button
          className="exportBtn"
          onClick={() => exportHandler()}
          disabled={isLoadingBtn}
        >
          {t("exportReport")}
          {isLoadingBtn && (
            <span className="spinner-border" role="status"></span>
          )}
        </button>
      </div>

      <div className="tableDiv">
        <Table
          columns={columns}
          data={data}
          fetchData={fetchData}
          loading={isLoading}
          TotalRows={pageCount}
          tableHeader={t("AllCampaigns")}
        />
      </div>

      <CampaignDetailsPopUp campaignID={campaignID} />
      <ResendPopUp campaignID={campaignID} smsCount={smsCount} />
    </div>
  );
}
