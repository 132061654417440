import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useContext,
  useRef,
} from "react";
import {
  GetReports,
  GetUserDetailsReport,
  GetSenders,
  GetDetailsReportAdmin,
  GetAdminReport,
  GetSenderOperatorsReport
} from "../../API";
import Table from "../../component/newTable";
import { useTranslation } from "react-i18next";
import { Context } from "../../context";
import Header from "../../component/header";
import DateRangePicker from "../../component/dateRangePicker";
import "./style.css";

export default function Reports() {
  const { role } = useContext(Context);
  const [data, setData] = useState([]);
  const { startDate, endDate } = useContext(Context);
  const [senderList, setSenderList] = useState([]);
  const [selectedSender, setSelectedSender] = useState();
  const [filterSender, setFilterSender] = useState();
  const [filterClient, setFilterClient] = useState();
  const requestsDelay = useRef(null);
  const lastSearchSender = useRef(null);
  const lastSearchClient = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [pageCount, setPageCount] = useState();
  const [toggleSideBar] = useState(false);
  const { t } = useTranslation();

  const columns = useMemo(
    () => [
      {
        Header: t("date"),
        accessor: "Date",
      },
      {
        Header: t("length"),
        accessor: "Length",
      },
      {
        Header: t("count"),
        accessor: "SMSCount",
      },
    ],
    [t]
  );

  const columns2 = useMemo(
    () => [
      {
        Header: (
          <div className="tableHeaderFilter">
            <div>{t("Client")}</div>
            <input
              type="text"
              className="searchFilter"
              value={filterClient}
              onChange={(e) => setFilterClient(e.target.value)}
              placeholder="Search"
            />
          </div>
        ),
        accessor: "Client",
      },
      {
        Header: (
          <div className="tableHeaderFilter">
            <div>{t("sender")}</div>
            <input
              type="text"
              className="searchFilter"
              value={filterSender}
              onChange={(e) => setFilterSender(e.target.value)}
              placeholder="Search"
            />
          </div>
        ),
        accessor: "Sender",
      },
      {
        Header: t("count"),
        accessor: "SMSCount",
      },
      {
        Header: t("length"),
        accessor: "SMSLength",
      },
    ],
    [t]
  );
  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {

      GetSenderOperatorsReport({
        "@page": pageIndex,
        "@rowsPerPage": pageSize,
        "@startDate": new Intl.DateTimeFormat("en").format(startDate),
        "@endDate": new Intl.DateTimeFormat("en").format(endDate),
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {

        });
      setIsLoading(true);
      setData([]);
      lastSearchSender.current = filterSender;
      lastSearchClient.current = filterClient;
      if (requestsDelay.current) return;

      if (
        selectedSender &&
        role &&
        role !== "1" &&
        localStorage.getItem("admin") !== "2" &&
        localStorage.getItem("userId") !== "356"
      )
        GetReports({
          "@page": pageIndex,
          "@rowsPerPage": pageSize,
          "@sender": selectedSender,
          "@startDate": new Intl.DateTimeFormat("en").format(startDate),
          "@endDate": new Intl.DateTimeFormat("en").format(endDate),
        })
          .then((response) => {
            setIsLoading(false);

            setData(response.data.table1);
            setPageCount(response.data.table2[0].Total);
          })
          .catch((error) => {
            setIsLoading(false);
          });

      requestsDelay.current = setTimeout(() => {
        requestsDelay.current = null;
        if (
          role &&
          (localStorage.getItem("admin") === "1" ||
            localStorage.getItem("admin") === "2" ||
            localStorage.getItem("userId") === "356")
        )
          GetAdminReport({
            "@page": pageIndex,
            "@rowsPerPage": pageSize,
            "@senderFilter": filterSender,
            "@clientFilter": filterClient,
            "@startDate": new Intl.DateTimeFormat("en").format(startDate),
            "@endDate": new Intl.DateTimeFormat("en").format(endDate),
          })
            .then((response) => {
              setIsLoading(false);
              setData(response.data.table1);
              setPageCount(response.data.table2[0].Total);
            })
            .catch((error) => {
              setIsLoading(false);
            });
      }, 2000);
    },
    [selectedSender, filterSender, filterClient, startDate, endDate, t, role]
  );
  useEffect(() => {
    if (
      localStorage.getItem("admin") !== "1" &&
      localStorage.getItem("admin") !== "2" &&
      localStorage.getItem("userId") !== "356"
    )
      GetSenders().then((response) => {
        setSenderList(response.data.table1);
        if (response.data.table1.length != 0) {
          setSelectedSender(response.data.table1[0].ID);
        }
      });
  }, [t, role]);

  const exportHandler = () => {
    setIsLoadingBtn(true);
    GetUserDetailsReport({
      "@sender": selectedSender,
      "@startDate": new Intl.DateTimeFormat("en").format(startDate),
      "@endDate": new Intl.DateTimeFormat("en").format(endDate),
    })
      .then((response) => {
        setIsLoadingBtn(false);
        let link = document.createElement("a");

        link.download = "Report";
        link.href =
          "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
          response.data;

        if (/android|iPad|iPhone|iPod/i.test(navigator.userAgent))
          link.download += ".xlsx";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        setIsLoadingBtn(false);
      });
  };
  const exportHandlerAdmin = () => {
    setIsLoadingBtn(true);
    GetDetailsReportAdmin({
      "@startDate": new Intl.DateTimeFormat("en").format(startDate),
      "@endDate": new Intl.DateTimeFormat("en").format(endDate),
      "@senderFilter": filterSender,
      "@clientFilter": filterClient,
    })
      .then((response) => {
        setIsLoadingBtn(false);
        let link = document.createElement("a");

        link.download = "Report";
        link.href =
          "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
          response.data;

        if (/android|iPad|iPhone|iPod/i.test(navigator.userAgent))
          link.download += ".xlsx";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        setIsLoadingBtn(false);
      });
  };
  return (
    <div className="reportdContainer">
      <Header
        title={t("reportsTitle")}
        toggleSideBar={() => toggleSideBar((s) => !s)}
      />
      <div className="tableMainTitles">
        <DateRangePicker />

        {localStorage.getItem("admin") !== "1" &&
          localStorage.getItem("admin") !== "2" &&
          localStorage.getItem("userId") !== "356" && (
            <select
              className="form-select filterList"
              value={selectedSender}
              onChange={(e) => setSelectedSender(e.target.value)}
            >
              {senderList &&
                senderList.map((s, i) => (
                  <option value={s.ID} key={i}>
                    {s.Name}
                  </option>
                ))}
            </select>
          )}

        {localStorage.getItem("admin") !== "1" &&
          localStorage.getItem("admin") !== "2" &&
          localStorage.getItem("userId") !== "356" && (
            <button
              className="exportBtn"
              onClick={() => exportHandler()}
              disabled={isLoadingBtn}
            >
              {t("exportReport")}
              {isLoadingBtn && (
                <span className="spinner-border" role="status"></span>
              )}
            </button>
          )}
        {(localStorage.getItem("admin") === "1" ||
          localStorage.getItem("admin") === "2" ||
          localStorage.getItem("userId") === "356") && (
            <button
              className="exportBtn"
              onClick={() => exportHandlerAdmin()}
              disabled={isLoadingBtn}
            >
              {t("exportReport")}
              {isLoadingBtn && (
                <span className="spinner-border" role="status"></span>
              )}
            </button>
          )}
      </div>
      {selectedSender &&
        localStorage.getItem("admin") !== "1" &&
        localStorage.getItem("admin") !== "2" &&
        localStorage.getItem("userId") !== "356" && (
          <div className="tableDiv">
            <Table
              columns={columns}
              data={data}
              fetchData={fetchData}
              loading={isLoading}
              TotalRows={pageCount}
            />
          </div>
        )}
      {(localStorage.getItem("admin") === "1" ||
        localStorage.getItem("admin") === "2" ||
        localStorage.getItem("userId") === "356") && (
          <div className="tableDiv">
            {" "}
            <Table
              columns={columns2}
              data={data}
              fetchData={fetchData}
              loading={isLoading}
              TotalRows={pageCount}
              tableHeader={t("reportsTitle")}
            />
          </div>
        )}
    </div>
  );
}
